// src/firebase.js

import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth"; // Importing authentication
import { getFirestore } from "firebase/firestore"; // Importing firestore
import { getStorage } from "firebase/storage";


// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBLmOey3ElMZ34c5WKHGy_yEVDMp-7pxtA",
  authDomain: "aussierulesmadness.com",
  projectId: "aussie-rules-madness",
  storageBucket: "aussie-rules-madness.firebasestorage.app",
  messagingSenderId: "919614553408",
  appId: "1:919614553408:web:6a843bb2b3964bc3694ea5",
  measurementId: "G-11E4JZKKVE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
export const storage = getStorage(app);


export { auth, db, GoogleAuthProvider }; // Export these for use in other files
