import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthContext";

function Header() {
  const { isLoggedIn, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <header className="bg-gradient-to-r from-gray-900 via-gray-800 to-black shadow-lg fixed top-0 left-0 w-full z-50">
      <div className="container mx-auto px-4 sm:px-6 md:px-8 py-4 flex items-center justify-between">
        {/* Logo */}
        <div className="text-2xl sm:text-3xl font-bold">
          <Link
            to="/"
            className="text-purple-500 hover:text-purple-400 transition-colors duration-200"
          >
            Aussie Rules Madness
          </Link>
        </div>

        {/* Hamburger Menu Toggle */}
        <button
          className="text-gray-300 sm:hidden focus:outline-none"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={
                isMenuOpen
                  ? "M6 18L18 6M6 6l12 12"
                  : "M4 6h16M4 12h16M4 18h16"
              }
            ></path>
          </svg>
        </button>

        {/* Desktop Navigation */}
        <nav className="hidden sm:flex space-x-8">
          <Link
            to="/about-us"
            className="text-gray-300 hover:text-white transition duration-200 text-lg font-semibold"
          >
            About Us
          </Link>
          <Link
            to="/comp-rules"
            className="text-gray-300 hover:text-white transition duration-200 text-lg font-semibold"
          >
            Competition Rules
          </Link>
          {isLoggedIn ? (
            <>
              <Link
                to="/my-predictions"
                className="text-gray-300 hover:text-white transition duration-200 text-lg font-semibold"
              >
                My Predictions
              </Link>
              <Link
                to="/profile"
                className="text-gray-300 hover:text-white transition duration-200 text-lg font-semibold"
              >
                My Profile
              </Link>
            </>
          ) : (
            <Link
              to="/auth"
              className="text-gray-300 hover:text-white transition duration-200 text-lg font-semibold"
            >
              Login / Sign Up
            </Link>
          )}
        </nav>

        {/* Logout Button */}
        {isLoggedIn && (
          <button
            onClick={handleLogout}
            className="hidden sm:block bg-purple-500 text-white py-2 px-4 rounded-lg hover:bg-purple-700 transition duration-200 text-lg font-semibold"
          >
            Logout
          </button>
        )}
      </div>

      {/* Mobile Dropdown Menu */}
      {isMenuOpen && (
        <div className="sm:hidden bg-gray-900 text-gray-300 shadow-lg">
          <nav className="flex flex-col space-y-4 px-4 py-2">
            <Link
              to="/about-us"
              className="hover:text-white transition duration-200 text-lg font-semibold"
              onClick={() => setIsMenuOpen(false)}
            >
              About Us
            </Link>
            <Link
              to="/comp-rules"
              className="hover:text-white transition duration-200 text-lg font-semibold"
              onClick={() => setIsMenuOpen(false)}
            >
              Competition Rules
            </Link>
            {isLoggedIn ? (
              <>
                <Link
                  to="/my-predictions"
                  className="hover:text-white transition duration-200 text-lg font-semibold"
                  onClick={() => setIsMenuOpen(false)}
                >
                  My Predictions
                </Link>
                <Link
                  to="/profile"
                  className="hover:text-white transition duration-200 text-lg font-semibold"
                  onClick={() => setIsMenuOpen(false)}
                >
                  My Profile
                </Link>
                <button
                  onClick={handleLogout}
                  className="text-left bg-purple-500 text-white py-2 px-4 rounded-lg hover:bg-purple-700 transition duration-200 text-lg font-semibold"
                >
                  Logout
                </button>
              </>
            ) : (
              <Link
                to="/auth"
                className="hover:text-white transition duration-200 text-lg font-semibold"
                onClick={() => setIsMenuOpen(false)}
              >
                Login / Sign Up
              </Link>
            )}
          </nav>
        </div>
      )}
    </header>
  );
}

export default Header;
