import React from "react";

function TermsAndConditions() {
  return (
    <div className="pt-20 min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-indigo-900 via-gray-800 to-gray-900 text-white">
      <div className="bg-gray-800 shadow-lg rounded-lg p-6 sm:p-8 max-w-4xl w-full">
        <h1 className="text-3xl sm:text-4xl font-bold text-center mb-6">
          Terms and Conditions
        </h1>

        <section className="space-y-6 text-sm sm:text-base">
          <p>
            Welcome to <span className="font-semibold">Aussie Rules Madness</span>! These Terms and Conditions ("Terms") govern your use of the Aussie Rules Madness website ("Website"), located at{" "}
            <span className="text-indigo-400">www.aussierulesmadness.com</span>.
            By accessing or using our Website, you agree to comply with and be
            bound by these Terms. If you do not agree to these Terms, please do
            not use our Website.
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            1. Eligibility
          </h2>
          <ul className="list-disc ml-6 space-y-2">
            <li>You must be 18 years or older to participate in the Aussie Rules Madness competition.</li>
            <li>By using our Website, you confirm that you are legally allowed to participate in competitions in your jurisdiction.</li>
            <li>The competition is free to enter, and only one prediction per user is allowed.</li>
          </ul>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            2. Competition Rules
          </h2>
          <ul className="list-disc ml-6 space-y-2">
            <li>Each user must create an account to participate in the ladder prediction competition.</li>
            <li>Users are allowed to submit <span className="font-semibold">only one prediction</span> for the AFL ladder.</li>
            <li>Predictions must be submitted before the competition lockout period begins. Once the lockout occurs, no changes to predictions will be allowed.</li>
            <li>
              Predictions that exactly match the final AFL ladder at the end of
              the season will be eligible for the prize pool of{" "}
              <span className="font-semibold">$100,000 AUD</span>.
            </li>
            <li>Any attempt to create multiple accounts to submit additional predictions may result in disqualification.</li>
          </ul>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            3. Account Creation
          </h2>
          <ul className="list-disc ml-6 space-y-2">
            <li>You are responsible for providing accurate and up-to-date information when creating your account.</li>
            <li>You are responsible for maintaining the confidentiality of your account credentials. Aussie Rules Madness will not be liable for any unauthorized access to your account.</li>
          </ul>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            4. Intellectual Property
          </h2>
          <p>
            All content on this Website, including text, images, logos, and
            designs, is the property of Aussie Rules Madness or its licensors
            and is protected by intellectual property laws. You may not
            reproduce, distribute, or use any content from this Website without
            prior written permission.
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            5. Limitation of Liability
          </h2>
          <ul className="list-disc ml-6 space-y-2">
            <li>Aussie Rules Madness is not responsible for any technical issues, website downtime, or errors that may prevent you from submitting your prediction.</li>
            <li>By participating in the competition, you acknowledge that your participation is at your own risk.</li>
            <li>In the event of disputes regarding the competition or its outcome, Aussie Rules Madness reserves the right to make the final decision.</li>
          </ul>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            6. Prize Distribution
          </h2>
          <ul className="list-disc ml-6 space-y-2">
            <li>
              The prize pool of{" "}
              <span className="font-semibold">$100,000 AUD</span> will be
              awarded to the user(s) whose prediction exactly matches the final
              AFL ladder at the end of the season.
            </li>
            <li>
              In the event of multiple correct predictions, the prize will be
              split evenly among the winners.
            </li>
            <li>Aussie Rules Madness reserves the right to verify the eligibility and identity of any winner(s) before distributing the prize.</li>
          </ul>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            7. Privacy Policy
          </h2>
          <p>
            By using this Website, you agree to the collection and use of your
            personal information as outlined in our Privacy Policy. Your
            personal information will only be used for account management,
            competition entry, and related purposes.
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            8. Changes to Terms
          </h2>
          <p>
            Aussie Rules Madness reserves the right to update these Terms at
            any time. Changes will be effective immediately upon posting on the
            Website. It is your responsibility to review the Terms periodically
            to stay informed of any updates.
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            9. Governing Law
          </h2>
          <p>
            These Terms are governed by and construed in accordance with the
            laws of <span className="font-semibold">[Your Country/State]</span>.
            Any disputes arising from or in connection with these Terms shall
            be subject to the exclusive jurisdiction of the courts in{" "}
            <span className="font-semibold">[Your Country/State]</span>.
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            10. Contact Us
          </h2>
          <p>If you have any questions about these Terms, please contact us at:</p>
          <ul className="list-disc ml-6 space-y-2">
            <li>
              Email: <span className="text-indigo-400">[Your Email Address]</span>
            </li>
            <li>
              Address:{" "}
              <span className="text-indigo-400">[Your Business Address]</span>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}

export default TermsAndConditions;
