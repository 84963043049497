import { db } from "../firebase";
import { query, collection, where, getDocs } from "firebase/firestore";

export const checkForDuplicateIPs = async (userIp) => {
  const q = query(collection(db, "users"), where("ip", "==", userIp));
  const querySnapshot = await getDocs(q);
  return !querySnapshot.empty; // Returns true if IP already exists
};

// src/utils/ipUtils.js

export const getUserIpAddress = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip; // Return the IP address
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return null; // Return null if there's an error
    }
  };
  