import React, { useState } from "react";
import AdelaideCrowsLogo from "../logos/adelaide-crows.png";
import BrisbaneLionsLogo from "../logos/brisbane-lions.png";
import CarltonBluesLogo from "../logos/carlton-blues.png";
import CollingwoodMagpiesLogo from "../logos/collingwood-magpies.png";
import EssendonBombersLogo from "../logos/essendon-bombers.png";
import FremantleDockersLogo from "../logos/fremantle-dockers.png";
import GeelongCatsLogo from "../logos/geelong-cats.png";
import GoldCoastSunsLogo from "../logos/gold-coast-suns.png";
import GWSGiantsLogo from "../logos/gws-giants.png";
import HawthornHawksLogo from "../logos/hawthorn-hawks.png";
import MelbourneDemonsLogo from "../logos/melbourne-demons.png";
import NorthMelbourneKangaroosLogo from "../logos/north-melbourne-kangaroos.png";
import PortAdelaidePowerLogo from "../logos/port-adelaide-power.png";
import RichmondTigersLogo from "../logos/richmond-tigers.png";
import StKildaSaintsLogo from "../logos/st-kilda-saints.png";
import SydneySwansLogo from "../logos/sydney-swans.png";
import WestCoastEaglesLogo from "../logos/west-coast-eagles.png";
import WesternBulldogsLogo from "../logos/western-bulldogs.png";

function LadderPredictionForm({ onSave, initialPrediction }) {
  const aflTeams = [
    { name: "Adelaide Crows", logo: AdelaideCrowsLogo },
    { name: "Brisbane Lions", logo: BrisbaneLionsLogo },
    { name: "Carlton Blues", logo: CarltonBluesLogo },
    { name: "Collingwood Magpies", logo: CollingwoodMagpiesLogo },
    { name: "Essendon Bombers", logo: EssendonBombersLogo },
    { name: "Fremantle Dockers", logo: FremantleDockersLogo },
    { name: "Geelong Cats", logo: GeelongCatsLogo },
    { name: "Gold Coast Suns", logo: GoldCoastSunsLogo },
    { name: "Greater Western Sydney Giants", logo: GWSGiantsLogo },
    { name: "Hawthorn Hawks", logo: HawthornHawksLogo },
    { name: "Melbourne Demons", logo: MelbourneDemonsLogo },
    { name: "North Melbourne Kangaroos", logo: NorthMelbourneKangaroosLogo },
    { name: "Port Adelaide Power", logo: PortAdelaidePowerLogo },
    { name: "Richmond Tigers", logo: RichmondTigersLogo },
    { name: "St Kilda Saints", logo: StKildaSaintsLogo },
    { name: "Sydney Swans", logo: SydneySwansLogo },
    { name: "West Coast Eagles", logo: WestCoastEaglesLogo },
    { name: "Western Bulldogs", logo: WesternBulldogsLogo },
  ];

  const [predictions, setPredictions] = useState(
    initialPrediction?.predictions || aflTeams
  );
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [displayName, setDisplayName] = useState(
    initialPrediction?.displayName || ""
  );
  const [isDisplayNameStep, setIsDisplayNameStep] = useState(false);

  const handleDragStart = (index, event) => {
    setDraggingIndex(index);

    // Remove the default drag preview
    const emptyImage = new Image();
    emptyImage.src = "";
    event.dataTransfer.setDragImage(emptyImage, 0, 0);
  };

  const handleDragOver = (index, event) => {
    event.preventDefault();

    // Reorder the list
    const updatedPredictions = [...predictions];
    const [draggedItem] = updatedPredictions.splice(draggingIndex, 1);
    updatedPredictions.splice(index, 0, draggedItem);
    setDraggingIndex(index);
    setPredictions(updatedPredictions);
  };

  const handleDragEnd = () => {
    setDraggingIndex(null);
  };

  const handleSave = () => {
    setIsDisplayNameStep(true);
  };

  const handleFinalSave = () => {
    if (displayName.trim() !== "") {
      const newPrediction = { displayName, predictions: [...predictions] };
      console.log("Saving Prediction:", newPrediction);
      onSave(newPrediction);
      setIsDisplayNameStep(false);
    } else {
      alert("Please enter a display name to save your prediction.");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-800 text-white px-4 sm:px-6 md:px-8 py-4">
      <div className="w-full max-w-xl sm:max-w-2xl bg-gray-700 p-4 sm:p-6 rounded-lg shadow-lg">
        {!isDisplayNameStep ? (
          <div>
            <p className="mb-4 text-center text-lg sm:text-xl">
              Drag and drop to reorder your prediction:
            </p>
            <ul className="space-y-2">
              {predictions.map((team, index) => (
                <React.Fragment key={team.name}>
                  <li
                    draggable
                    onDragStart={(e) => handleDragStart(index, e)}
                    onDragOver={(e) => handleDragOver(index, e)}
                    onDragEnd={handleDragEnd}
                    className={`p-3 bg-gray-600 rounded flex items-center space-x-4 cursor-move transition-all ${
                      draggingIndex === index
                        ? "scale-110 shadow-2xl bg-indigo-600"
                        : "hover:scale-105 hover:shadow-lg"
                    }`}
                  >
                    <div className="w-6 text-center font-bold">{index + 1}</div>
                    <img
                      src={team.logo}
                      alt={team.name}
                      className="w-8 h-8 sm:w-10 sm:h-10 object-contain"
                    />
                    <span className="text-sm sm:text-base">{team.name}</span>
                  </li>
                  {index === 7 && (
                    <div className="relative flex items-center my-2">
                      <div className="absolute -left-10 bg-indigo-500 text-white text-xs font-bold px-3 py-1 rounded-full">
                        TOP 8
                      </div>
                      <div className="flex-1 border-t-2 border-indigo-500 mt-1 ml-4"></div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </ul>
            <button
              onClick={handleSave}
              className="w-full mt-6 bg-indigo-500 text-white py-2 rounded hover:bg-indigo-700 transition-all"
            >
              Save Prediction
            </button>
          </div>
        ) : (
          <div>
            <p className="mb-4 text-center text-lg sm:text-xl">
              Enter a display name:
            </p>
            <input
              type="text"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              className="w-full px-3 py-2 rounded bg-gray-600 text-white"
              placeholder="Display Name"
            />
            <button
              onClick={handleFinalSave}
              className="w-full mt-4 bg-indigo-500 text-white py-2 rounded hover:bg-indigo-700 transition-all"
            >
              Finalize and Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default LadderPredictionForm;
