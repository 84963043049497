import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase"; // Ensure this is the correct path to your Firebase configuration
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent! Check your inbox.");
      setError(""); // Clear any previous errors
    } catch (err) {
      console.error("Error sending password reset email:", err);
      setMessage(""); // Clear previous success messages
      setError("Failed to send password reset email. Please try again.");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-800 text-white px-6">
      <div className="w-full max-w-md bg-gray-700 p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-4 text-center">Forgot Password</h1>
        {message && <p className="text-green-400 text-center">{message}</p>}
        {error && <p className="text-red-400 text-center">{error}</p>}
        <form onSubmit={handleForgotPassword}>
          <div className="mb-4">
            <label className="block text-gray-300">Email Address</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full p-2 rounded bg-gray-600 text-white"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-indigo-500 text-white py-2 rounded hover:bg-indigo-700 transition-all"
          >
            Send Password Reset Email
          </button>
        </form>
        <button
          onClick={() => navigate("/auth")}
          className="w-full mt-4 bg-gray-500 text-white py-2 rounded hover:bg-gray-600 transition-all"
        >
          Back to Login
        </button>
      </div>
    </div>
  );
}

export default ForgotPassword;
