import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import ReCAPTCHA from "react-google-recaptcha";
import GoogleLogo from "../logos/google.png";

const getUserIpAddress = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error fetching IP address:", error);
    return null;
  }
};

function AuthPage() {
  const [isSignUp, setIsSignUp] = useState(false);
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);
  const [error, setError] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [emailListOptIn, setEmailListOptIn] = useState(false);
  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        const userIp = await getUserIpAddress();
        if (!userIp) {
          setError("Could not fetch IP address.");
          return;
        }

        await setDoc(userDocRef, {
          firstName: user.displayName?.split(" ")[0] || "Anonymous",
          lastName: user.displayName?.split(" ")[1] || "User",
          email: user.email,
          username: user.email.split("@")[0],
          phone: "",
          dob: "",
          ip: userIp,
          createdAt: new Date(),
          profileComplete: false,
          emailListOptIn: true,
        });

        navigate("/complete-profile");
      } else {
        const userData = userDoc.data();
        if (!userData.profileComplete) {
          navigate("/complete-profile");
        } else {
          navigate("/my-predictions");
        }
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError("");

    if (!agreeToTerms) {
      setError("You must agree to the privacy policy and terms of conditions.");
      return;
    }

    const userIp = await getUserIpAddress();
    if (!userIp) {
      setError("Could not fetch IP address.");
      return;
    }

    const age = new Date().getFullYear() - new Date(dob).getFullYear();
    if (age < 18) {
      setError("You must be 18 years or older to sign up.");
      return;
    }

    if (!captchaValue) {
      setError("Please complete the CAPTCHA.");
      return;
    }

    if (!firstName || !lastName || !dob || !phone || !username) {
      setError("Please fill in all the required fields.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        emailOrUsername,
        password
      );
      const user = userCredential.user;

      await sendEmailVerification(user);

      await setDoc(doc(db, "users", user.uid), {
        firstName,
        lastName,
        username,
        email: user.email,
        phone,
        dob,
        ip: userIp,
        createdAt: new Date(),
        profileComplete: true,
        emailListOptIn,
      });

      setError("Verification email sent! Please check your inbox.");
      navigate("/verify-email");
    } catch (err) {
      setError(err.message);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    try {
      let userEmail = emailOrUsername;
      if (!userEmail.includes("@")) {
        const userDocRef = doc(db, "users", userEmail);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          userEmail = userDoc.data().email;
        } else {
          setError("Username not found.");
          return;
        }
      }

      await signInWithEmailAndPassword(auth, userEmail, password);
      navigate("/my-predictions");
    } catch (err) {
      setError(err.message);
    }
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-black text-white px-4 sm:px-6 md:px-8">
      <div className="flex-grow flex items-center justify-center pt-24 pb-12">
        <div className="w-full max-w-sm sm:max-w-md bg-gray-800 p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl sm:text-3xl font-extrabold text-center mb-4">
            {isSignUp ? "Sign Up" : "Login"}
          </h2>
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          <form
            className="space-y-3"
            onSubmit={isSignUp ? handleSignUp : handleLogin}
          >
            {isSignUp && (
              <>
                <div>
                  <label className="block text-gray-300 mb-1">Username</label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 rounded-lg bg-gray-700 text-white border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label className="block text-gray-300 mb-1">First Name</label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 rounded-lg bg-gray-700 text-white border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label className="block text-gray-300 mb-1">Last Name</label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 rounded-lg bg-gray-700 text-white border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
              </>
            )}
            <div>
              <label className="block text-gray-300 mb-1">
                {isSignUp ? "Email" : "Email or Username"}
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 rounded-lg bg-gray-700 text-white border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={emailOrUsername}
                onChange={(e) => setEmailOrUsername(e.target.value)}
                required
              />
            </div>
            <div>
              <label className="block text-gray-300 mb-1">Password</label>
              <input
                type="password"
                className="w-full px-3 py-2 rounded-lg bg-gray-700 text-white border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {isSignUp && (
              <>
                <div>
                  <label className="block text-gray-300 mb-1">
                    Date of Birth
                  </label>
                  <input
                    type="date"
                    className="w-full px-3 py-2 rounded-lg bg-gray-700 text-white border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label className="block text-gray-300 mb-1">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    className="w-full px-3 py-2 rounded-lg bg-gray-700 text-white border border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </div>
                <div className="flex items-center mt-2">
                  <input
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    checked={emailListOptIn}
                    onChange={(e) => setEmailListOptIn(e.target.checked)}
                  />
                  <label className="ml-2 block text-gray-300">
                    Add me to the email list
                  </label>
                </div>
                <div className="flex items-center mt-2">
                  <input
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    checked={agreeToTerms}
                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                    required
                  />
                  <label className="ml-2 block text-gray-300">
                    I agree to the{" "}
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-400 hover:underline"
                    >
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a
                      href="/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-400 hover:underline"
                    >
                      Terms of Conditions
                    </a>
                  </label>
                </div>
                <div className="flex justify-center mt-2">
                  <ReCAPTCHA
                    sitekey="6LeJ5IAqAAAAAIuyUELaH2H6o6x2dds2PvvKdPiG"
                    onChange={onCaptchaChange}
                  />
                </div>
              </>
            )}
            <button
              type="submit"
              className="w-full bg-purple-500 py-2 rounded-lg text-white font-semibold hover:bg-purple-700 transition duration-200 shadow-md"
            >
              {isSignUp ? "Sign Up" : "Login"}
            </button>
            <div className="flex items-center justify-center my-4">
              <div className="border-t border-gray-500 w-1/4"></div>
              <span className="text-gray-400 mx-2">or</span>
              <div className="border-t border-gray-500 w-1/4"></div>
            </div>
            <button
              type="button"
              onClick={handleGoogleLogin}
              className="flex items-center justify-center w-full bg-white text-gray-700 border border-gray-300 py-2 rounded-lg shadow-md hover:bg-gray-100 transition duration-200"
            >
              <img
                src={GoogleLogo}
                alt="Google Logo"
                className="w-5 h-5 mr-2"
              />
              <span>
                {isSignUp ? "Sign Up with Google" : "Login with Google"}
              </span>
            </button>
          </form>
          <div className="mt-4 text-center">
            <span
              onClick={() => setIsSignUp(!isSignUp)}
              className="text-blue-400 hover:underline cursor-pointer"
            >
              {isSignUp
                ? "Already have an account? Login here."
                : "Don't have an account? Sign up here."}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthPage;
