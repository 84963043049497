import React from "react";
import { FaTwitter, FaInstagram } from "react-icons/fa";

function Footer() {
  return (
    <footer className="bg-gradient-to-r from-gray-900 via-gray-800 to-black text-gray-400 py-6">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-6">
        {/* Left Section - Copyright */}
        <p className="text-sm text-center md:text-left">
          © 2024 <span className="font-bold text-white">Aussie Rules Madness</span>. All rights reserved.
        </p>

        {/* Center Section - Terms Link */}
        <a
          href="/terms-and-conditions"
          className="text-sm text-indigo-400 hover:text-indigo-600 mt-4 md:mt-0 md:mx-6 transition duration-200"
        >
          Terms and Conditions
        </a>

        {/* Right Section - Social Media Links */}
        <div className="flex space-x-4 mt-4 md:mt-0">
          <a
            href="https://twitter.com/yourtwitter"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-indigo-400 transition duration-200 transform hover:scale-110"
            aria-label="Twitter"
          >
            <FaTwitter size={24} />
          </a>
          <a
            href="https://instagram.com/yourinstagram"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-indigo-400 transition duration-200 transform hover:scale-110"
            aria-label="Instagram"
          >
            <FaInstagram size={24} />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
