import React from "react";

function CompetitionRules() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-black text-white flex items-center justify-center px-4 sm:px-6 md:px-8">
      <div className="max-w-2xl sm:max-w-4xl">
        {/* Title */}
        <h1 className="text-3xl sm:text-4xl font-extrabold mb-6 sm:mb-8 text-center text-purple-500">
          Competition Rules
        </h1>

        {/* Introduction */}
        <p className="mb-6 text-base sm:text-lg leading-relaxed text-center text-gray-300">
          Welcome to the Aussie Rules Madness competition! Please read the rules
          below to ensure you understand how the competition works and how you
          can win the prize.
        </p>

        {/* Rules Section */}
        <div className="text-left">
          <h2 className="text-xl sm:text-2xl font-semibold mt-6 text-yellow-400">
            1. Overview
          </h2>
          <p className="mb-4 text-base sm:text-lg text-gray-300">
            Predict the final AFL ladder and win the <span className="text-indigo-400 font-bold">$100,000</span> prize!
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6 text-yellow-400">
            2. Eligibility
          </h2>
          <p className="mb-4 text-base sm:text-lg text-gray-300">
            Participants must be 18 years or older and reside in Australia.
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6 text-yellow-400">
            3. How to Enter
          </h2>
          <p className="mb-4 text-base sm:text-lg text-gray-300">
            Create an account and submit your AFL ladder prediction before the
            lockout date.
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6 text-yellow-400">
            4. Terms & Conditions
          </h2>
          <p className="text-base sm:text-lg text-gray-300">
            For more information, please refer to our{" "}
            <a
              href="/terms"
              className="text-indigo-400 underline hover:text-indigo-600 transition duration-200"
            >
              Terms and Conditions
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export default CompetitionRules;
